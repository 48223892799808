import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import contentParser from 'gatsby-wpgraphql-inline-images';
import Breadcrumb from '../components/breadCrumb'
import Img from "gatsby-image"

const CoverImage = ({image}) => {
    if (!image) return null

    return (
        <>
            <Img 
                className="w-full" 
                fluid={image.imageFile.childImageSharp.fluid} 
                alt={image.altText} 
            />
        </>
    )
}

const BlogPage = (props) => {
    const { pageContext } = props
    const { title, content, featuredImage } = pageContext.node
    const wpContent = content ? contentParser({ content }, pageContext.plugins.wpImage) : undefined;
    console.log(featuredImage)
    return (
        <Layout>
            <SEO title={title} description={title} />
            <CoverImage image={featuredImage} />
            <div className={`container mx-auto p-6 ${featuredImage ? 'pb-16' : 'py-16' }`}>
            
                <div className="flex flex-wrap">
                    <Breadcrumb baseUri={`/blog`} baseTitle={'Blog'} pageTitle={title} />
                    <section className={`w-full p-3`}>    
                        <h1 className="font-bold text-5xl md:text-6xl mb-3 text-primary">
                            {title}
                        </h1>              
                        <div id="main-content-block">
                            <section className="content-block">
                                {wpContent}
                            </section>
                        </div>
                    </section>
                </div>
            </div>
        </Layout>
    )
}

export default BlogPage